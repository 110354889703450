import React, { useState, useContext } from "react"
import styled from 'styled-components'
import { FirebaseContext } from '@components/Firebase'
import { Button } from '@components/common'
import Form from '@form/Form'
import Text from '@typo/Text'
import MainHeading from '@typo/MainHeading'
import FlashMessage from '@notification/FlashMessage'
import { useTranslation } from 'react-i18next'


const PageWrapper = styled.div`
  background-color: ${props => props.theme.backgroundColor};
`

const CheckButtonWrapper = styled.div`
  margin-top: 15px;
`

const CouponFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GridWrapper =  styled.div`
  height:90vh;
  display:grid;
  place-items:center;
`

const Input =  styled.input`
  width:100%;
  label{
    font-size:12px;
    content: '${props => props.label}aaaaa';
    position:absolute;
    top:10px;
    opacity:0.8;
  }
  border:none;
  position:relative;
  background-color:white;
  height:65px;
  padding: 30px 15px 15px 15px;
  font-family:'poppins';
  border-radius:15px;
  margin-bottom: 15px;
  &:focus, &:active{
    font-family:'poppins';
    font-size:1rem;
  }
`


const RedeemPromotion = (props) => {
  const [flashMessages, setFlashMessages] = useState([]);
  const {firebase,user} = useContext(FirebaseContext);
  const [coupon, setCoupon] = useState('')
  const { t  } = useTranslation()
  const lang = props.pageContext.lang


  function handleSubmit(e){
    e.preventDefault(); // prevent redirect after submit
    const sanatizedCoupon = coupon.replace(/\s/g, '').toLowerCase();
    firebase.checkPromotionCode(sanatizedCoupon).then((res) => {
      setFlashMessages(res.data)
    }).catch((err) => {
      setFlashMessages([{message: err.message, type:"error"}])
    })
  }

  return (
    <PageWrapper>
      {user && 
        <GridWrapper  >
        <CouponFormWrapper>
          <Form onSubmit={handleSubmit}>
          <MainHeading center >{t('redeem-promotion.title')}</MainHeading>
          <Text center>{t('redeem-promotion.subtitle')}</Text>
          <Input  value ={coupon} name="coupon" placeholder="coupon"  type="text" onChange = {e => {
            e.persist();
            setFlashMessages([]);
            setCoupon(e.target.value)
          }}/>
          <CheckButtonWrapper>
            <Button type="submit" block >{t('redeem-promotion.checkCouponButton')}</Button>
          </CheckButtonWrapper>
          </Form>
          {flashMessages.map((message) => (
            <FlashMessage message={{
              message:t(`promotion-feedback.${message.type}`,{promoCode:message.code, duration: message?.duration, storyTitle: message?.storyTitles?.[lang]}),
              type:message.status}}
            />
          ))}
        </CouponFormWrapper>
        </GridWrapper>
      }
    </PageWrapper>
  );
}

export default RedeemPromotion